import React from 'react'
import PropTypes from 'prop-types'

const ButtonTile = props => (
	<a className={props.classname}
		href={props.link}
		target="_blank" rel="noopener noreferrer">
		<span className="icon align-icon">
			<i className={props.icon}></i>
		</span>&nbsp;
		<span className="is-size-5 is-uppercase">{props.text}</span>
	</a>
)

export default ButtonTile

ButtonTile.propTypes = {
	classname: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired
}