import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import Layout from '../components/common/layout'
import SEO from '../components/common/seo'
import ContactFrom from '../components/contact/contactForm'
import ButtonTile from '../components/common/buttonTile'

const ContactPage = ({ data }) => {

	const pageDetails = data.contact.edges[0].node

	return (
		<Layout pageTitle={pageDetails.title}>

			<SEO
				title={pageDetails.title}
				description={pageDetails.description}
				img={pageDetails.shareImage.file.url}
			/>
			<p className="subtitle has-text-centered">Want to get in touch? Use the form below to contact us, or follow on social media.</p>

			<article className="page-content content is-size-5">
				<div className="columns contact-content">
					<div className="column is-8">
						<ContactFrom />
					</div>

					<div className="column is-4 contact-social-tiles">
						<div className="tile is-parent">
							<ButtonTile classname="tile is-child contact-social-tile notification has-text-centered" link={data.social.instagram} icon="ri-instagram-line" text="Instagram" />
						</div>

						<div className="tile is-parent">
							<ButtonTile classname="tile is-child contact-social-tile notification has-text-centered" link={data.social.youtube} icon="ri-youtube-fill" text="YouTube" />
						</div>

						<div className="tile is-parent">
							<ButtonTile classname="tile is-child contact-social-tile notification has-text-centered" link={`mailto:${data.social.email}`} icon="ri-mail-fill" text="Email" />
						</div>
					</div>
				</div>
			</article>

		</Layout >
	)
}

ContactPage.propTypes = {
	data: PropTypes.shape({
		social: PropTypes.shape({
			email: PropTypes.string.isRequired,
			instagram: PropTypes.string.isRequired,
			youtube: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
}

export default ContactPage

export const contactQuery = graphql`
	query ContactQuery{
		social:contentfulPerson(name: {eq: "Toyosi Soetan"}) {
			email
			instagram
			youtube
		}
		contact:allContentfulPageDetails(filter: {title: {eq: "Contact"}}) {
			edges {
				node {
					title
					description
					shareImage {
						file {
							url
						}
					}
				}
			}
		}
	}
`
