import React, { useState } from 'react'
import { Formik } from 'formik'
import axios from 'axios'

function ContactForm() {
	const [success, setSuccess] = useState(false)
	const [showMessage, setShowMessage] = useState(false)
	const successMsg = <span className="help has-text-centered has-text-success has-text-weight-bold">Message sent successfully!<br />Thanks, {`I'll`} be in touch :)</span>
	const errorMsg = <span className="help has-text-centered has-text-danger has-text-weight-bold">Something went wrong :/<br />Please try again later...</span>
	const defaultMsg = <span className="help has-text-centered">Your details will only be used for the sole purpose of getting in touch with you.</span>
	const noSpecialChars = `No special characters or numbers please!`

	return (
		<Formik
			initialValues={{ name: ``, email: ``, subject: ``, message: `` }}
			validate={(values) => {
				let errors = {}
				// Email validation
				if (!values.email) {
					errors.email = `Please enter your email address`
				} else if (
					!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
				) {
					errors.email = `Please enter a valid email address`
				}

				// Name validation
				if (!values.name) {
					errors.name = `Please enter your name`
				} else if (
					(!/^[a-zA-Z\s]*$/.test(values.name))
				) {
					errors.name = noSpecialChars
				}

				// Subject validation
				if (!/^[a-zA-Z\s]*$/.test(values.subject)) {
					errors.subject = noSpecialChars
				}

				// Message validation
				if (!values.message) {
					errors.message = `Please enter a message`
				}

				return errors
			}}
			onSubmit={(values, { setSubmitting }) => {
				axios.post(process.env.GATSBY_CONTACT_API_URL, {
					email: values.email,
					name: values.name,
					subject: values.subject,
					content: values.message,
				})
					.then(function () {
						setSuccess(true)
						setShowMessage(true)
						setTimeout(() => {
							values.email = ``
							values.name = ``
							values.subject = ``
							values.message = ``
							setSuccess(false)
							setShowMessage(false)
							setSubmitting(false)
						}, 3000)
					})
					.catch(function () {
						setTimeout(() => {
							setSubmitting(false)
						}, 400)
						setSuccess(false)
						setShowMessage(true)
					})
			}}

		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
			}) => (
					<>
						<form className="contact-form" onSubmit={handleSubmit}>
							<div className="field is-horizontal">
								<div className="field-body">
									<div className="field">
										<p className="control is-expanded has-icons-left">
											<input
												className="input"
												type="text"
												name="name"
												placeholder="Name"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.name}
											/>
											<span className="help has-text-weight-semibold is-danger">{errors.name && touched.name && errors.name}</span>
											<span className="icon is-small is-left">
												<i className="ri-user-line" />
											</span>
										</p>
									</div>
									<div className="field">
										<p className="control is-expanded has-icons-left">
											<input
												className="input"
												type="email"
												name="email"
												placeholder="your@email.com"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.email} />
											<span className="help has-text-weight-semibold is-danger">{errors.email && touched.email && errors.email}</span>
											<span className="icon is-small is-left">
												<i className="ri-mail-line" />
											</span>
										</p>
									</div>
								</div>
							</div>

							<div className="field is-horizontal">
								<div className="field-body">
									<div className="field">
										<div className="control has-icons-left">
											<input
												className="input"
												type="text"
												name="subject"
												placeholder="Subject (optional)"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.subject} />
											<span className="help has-text-weight-semibold is-danger">{errors.subject && touched.subject && errors.subject}</span>
											<span className="icon is-small is-left">
												<i className="ri-information-line"></i>
											</span>
										</div>
									</div>
								</div>
							</div>

							<div className="field is-horizontal">
								<div className="field-body">
									<div className="field">
										<div className="control">
											<textarea
												className="textarea"
												name="message"
												placeholder="Your message"
												rows="4"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.message}
											/>
										</div>
									</div>
								</div>
							</div>
							<div>
								<span className="help has-text-left has-text-weight-semibold textarea-help is-danger">{errors.message && touched.message && errors.message}</span>
							</div>

							<div className="field is-horizontal">
								<div className="field-body">
									<div className="field field has-addons has-addons-centered">
										<div className="control">
											<button
												className="button is-link is-uppercase"
												type="submit"
												id="submit"
												disabled={isSubmitting}>
												Submit&nbsp;&nbsp;
												<span className="icon">
													<i className="ri-mail-send-line" />
												</span>
											</button>

										</div>
									</div>
								</div>
							</div>

							{(success && showMessage) &&
								successMsg
							}
							{(!success && showMessage) &&
								errorMsg
							}
							{(!success && !showMessage) &&
								defaultMsg
							}

						</form>
					</>
			)}
		</Formik>
	)
}
export default ContactForm